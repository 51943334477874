module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/ichwan/Documents/repos/new-personal-site/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js"},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/ichwan/Documents/repos/new-personal-site"},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-theme-terminal/gatsby-browser.js'),
      options: {"plugins":[],"source":["projects"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
